import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import Intro from '../components/Intro'
import Content from '../components/Content'

const Impressum = () => (
  <Layout>
    <SEO
      title="Datenschutz"
      keywords={[
        `Apps`,
        `Websites`,
        `Webseiten`,
        `Entwicklung`,
        `Programmierung`,
        `Homepage`,
        `Digital`,
      ]}
    />
    <Intro meta="Recht" main={<>Datenschutz</>} />
    <Content>
      <strong>I. Name und Anschrift</strong>
      <br />
      <br />
      Der Verantwortliche im Sinne der Datenschutz-Grundverordnung (DSGVO) ist:
      <br />
      <br />
      A45 Anstalt <br />
      Äulestrasse 45
      <br />
      FL – 9490 Vaduz <br />
      Postfach 3
      <br />
      T + 423 230 30 32
      <br />
      hallo@a45.li
      <br />
      <br />
      Verantwortlich für den Datenschutz in der A45 Anstalt ist Herr Bernhard
      Wanger.
      <br />
      <br />
      <strong>II. Allgemeines zur Datenverarbeitung</strong>
      <br />
      <br />
      1. Umfang der Verarbeitung personenbezogener Daten
      <br />
      Unsere Verarbeitung personenbezogener Daten unserer Nutzer beschränkt sich
      auf jene Daten, die zur Bereitstellung einer funktionsfähigen
      Internetseite sowie unserer Inhalte und Leistungen erforderlich sind. Die
      Verarbeitung personenbezogener Daten unserer Nutzer erfolgt nur zu den mit
      ihnen vereinbarten Zwecken oder wenn eine sonstige rechtliche Grundlage
      (im Sinn der DSGVO) vorliegt. Es werden nur solche personenbezogenen Daten
      erhoben, die für die Durchführung und Abwicklung unserer Aufgaben und
      Leistungen tatsächlich erforderlich sind oder die Sie uns freiwillig zur
      Verfügung gestellt haben.
      <br />
      <br />
      2. Ihre Rechte (Betroffenenrechte)
      <br />
      Sie haben das Recht, Auskunft über Ihre von uns verarbeiteten
      personenbezogenen Daten zu verlangen. Insbesondere können Sie Auskunft
      über die Verarbeitungszwecke, die Kategorien der personenbezogenen Daten,
      die Kategorien von Empfängern, gegenüber denen Ihre Daten offengelegt
      wurden oder werden, die geplante Speicherdauer, das Bestehen eines Rechts
      auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder
      Widerspruch, Datenübertragung , die Herkunft Ihrer Daten, sofern diese
      nicht bei uns erhoben wurden, sowie über das Bestehen einer
      automatisierten Entscheidungsfindung einschliesslich Profiling verlangen.{' '}
      <br />
      <br />
      Sie haben auch das Recht, eine allenfalls erteilte Einwilligung zur
      Nutzung Ihrer personenbezogenen Daten jederzeit zu widerrufen. <br />
      Sofern Sie der Auffassung sind, dass die Verarbeitung Ihrer
      personenbezogenen Daten durch uns im Widerspruch zu den geltenden
      Datenschutzbestimmungen steht, haben Sie die Möglichkeit, sich bei der
      Datenschutzstelle zu beschweren. <br />
      <br />
      <strong>III. Beschreibung und Umfang der Datenverarbeitung</strong>
      <br />
      <br />
      1. Bereitstellung der Webseite
      <br />
      Wie bei jeder Verbindung mit einem Webserver protokolliert und speichert
      der Server unseres Webhosting-Anbieters cyon in Basel, Schweiz, bestimmte
      technische Daten. <br />
      <br />
      Zu diesen Daten gehören:
      <br />
      — IP-Adresse und das Betriebssystem Ihres Geräts
      <br />
      — Daten der Zugriffszeit
      <br />
      — Art des Browsers <br />
      — Browser-Anfrage inklusive der Herkunft der Anfrage (Referrer) <br />
      <br />
      Dies ist aus technischen Gründen erforderlich, um Ihnen unsere Website zur
      Verfügung zu stellen. cyon schützt diese Daten mit technischen und
      organisatorischen Massnahmen vor unerlaubten Zugriffen und gibt sie nicht
      an Dritte weiter. Soweit wir dabei personenbezogene Daten verarbeiten, tun
      wir dies aufgrund unseres Interesses, Ihnen die bestmögliche
      Nutzererfahrung zu bieten und die Sicherheit und Stabilität unserer
      Systeme zu gewährleisten.
      <br />
      <br />
      2. Kontaktformular
      <br />
      Senden Sie uns eine Mail oder eine sonstige elektronische Nachricht,
      werden Ihre Angaben nur für die Bearbeitung der Anfrage, mögliche damit
      zusammenhängende weitere Fragen, gespeichert und nur im Rahmen der Anfrage
      verwendet. <br />
      <br />
      <strong>IV. Datensicherheit</strong>
      <br />
      <br />
      Wir verwenden innerhalb des Webseite-Besuchs das verbreitete
      RSA-Verschlüsselungsverfahren in Verbindung mit der jeweils höchsten
      Verschlüsselungsstufe, die von Ihrem Browser unterstützt wird. Ob eine
      einzelne Seite unseres Internetauftrittes verschlüsselt übertragen wird,
      erkennen Sie an der geschlossenen Darstellung des Schüssel-
      beziehungsweise Schloss-Symbols in der Adressleiste Ihres Browsers.
      <br />
      <br />
      Darüber hinaus wenden wir weitere geeignete technische und
      organisatorische Sicherheitsmassnahmen an, um Ihre Daten gegen zufällige
      oder vorsätzliche Manipulationen, teilweisen oder vollständigen Verlust,
      Zerstörung oder gegen den unbefugten Zugriff Dritter zu schützen. Unsere
      Sicherheitsmassnahmen werden entsprechend der technologischen Entwicklung
      fortlaufend verbessert.
      <br />
      <br />
      <br />
      <br />
    </Content>
  </Layout>
)

export default Impressum
